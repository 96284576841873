<template lang='pug'>

</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'Partner',
	props: ['hashLink'],
	metaInfo () { return {title: 'Partner'}},

	async created () {
		await this.view({linkHash: this.hashLink});
		this.rememberPartnerHash(this.hashLink);
        this.$router.push({name: 'index'})
	},

	methods : {
		...mapActions({
			view : 'users/partnerView'
		})
	}
}
</script>
